@import '~bootstrap/dist/css/bootstrap.min.css';


:root {
  --background-color: #00080e;
  --text-color: #ffffff;
  --primary-color: #dbfa60;
  --secondary-color: #e0e0e0;
  --font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  --border-radius: 8px;
}

* {
  box-sizing: border-box; /* Ensures padding doesn't affect overall width */
}

ul.features-list {
  list-style-type: none; /* Removes default list styling */
  padding-left: 0; /* Resets default padding */
  text-align: left;
}

ul.features-list li {
  position: relative; /* Establish relative positioning */
  line-height: 1.6; /* Adjust line height as needed */
  padding-left: 1.5em; /* Make room for custom bullet */
  margin-bottom: 10px; /* Space between list items */
}

/* Custom bullet point style */
ul.features-list li::before {
  content: ''; /* Use an empty string for custom bullet */
  position: absolute; /* Absolute position relative to the li */
  left: 0; /* Align to the left of the li */
  top: 50%; /* Position at the middle of the li */
  transform: translateY(-50%); /* Center vertically */
  height: 10px; /* Bullet height */
  width: 10px; /* Bullet width */
  background-color: #dbfa60; /* Bullet color */
  border-radius: 50%; /* Make it circular */
}

/* Your custom styles go here */
body {
  background-color: var(--background-color);
  /* more styles */
  body, h1, h2, h3, h4, h5, h6, p, li {
    color: #ffffff !important;
    font-size: 16px; /* Adjust base font size */
  }
}

@media (max-width: 768px) {
  body {
    font-size: 14px; /* Smaller font size for mobile devices */
  }
}

.pre-order-btn {
  background-color: #dbfa60;
  border-color: #dbfa60; /* Adjust the border color as well */
  color: #000; /* Text color */
  font-weight: bold; /* Make the text bold */
}

.pre-order-btn:hover, .pre-order-btn:focus {
  background-color: #c8e746; /* Lighter or darker shade for hover effect */
  border-color: #c8e746;
  color: black;
}

.pre-order-btn-black {
  color: black;
}

.custom-navbar {
  background-color: rgba(18, 18, 18, 0.95); /* Adjust the last number (alpha value) to control transparency */
}

.content {
  color: white;
}

.container .glow-article {
  margin: 1rem; /* Adjust the value as needed */
  display: flex;
  flex-direction: column;
  align-items: center;
  display: flex; 
  justify-content: center; 
  align-items: center;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid var(--secondary-color);
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  max-width: 100%; /* Ensure images are not wider than their container */
  height: auto; /* Maintain aspect ratio */
}

.logo div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  margin-left: 10px;
}

.logo span {
  color: var(--primary-color);
  font-size: 1.5em;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%; /* Full width */
  flex-wrap: wrap; /* Wrap items if they don't fit in one line */
  
}

.centered-content {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

section {
  padding: 40px 20px;
  margin: 0 auto;
  max-width: 95vw; /* Changed from fixed 1000px to relative viewport width */
  text-align: center;
}

section h2 {
  color: var(--primary-color);
  margin-bottom: 20px;
}

section ul {
  list-style-type: none;
  padding: 0;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
}

section ul li {
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative;
}

section ul li:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 10px;
  height: 10px;
  background-color: var(--primary-color);
  border-radius: 50%;
}

footer {
  padding: 40px 20px;
  background-color: rgba(18, 18, 18, 0.95);
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  header, section, footer {
    padding: 10px; /* Reduced padding */
  }

  .logo img {
    max-width: 100%; /* Ensure images are not wider than their container */
    height: auto; /* Maintain aspect ratio */
    }

  .logo span {
    font-size: 1.2em; /* Adjust font size for smaller screens */
  }
}

@media (max-width: 480px) {
  .logo span {
    font-size: 1em; /* Further reduce font size for very small screens */
  }
}

@media (max-width: 480px) {
  .logo span {
    font-size: 1em;
  }
}

.custom-table {
  background-color: #000; /* Black background color */
  border-color: #fff; /* White border color */
  color: #fff; /* White text color for table content (optional) */
    width: 100%;
    overflow-x: auto; /* Allows table to scroll on small devices */
  }


.custom-table th {
  background-color: #000; /* Black background color for table headers */
  color: #fff; /* White text color for table headers (optional) */
}

.custom-table td {
  background-color: #000; /* Black background color for table cells */
  color: #fff; /* White text color for table cells (optional) */
}

@media (max-width: 600px) {
  .custom-table th, .custom-table td {
    padding: 8px; /* Reduce padding */
    font-size: 12px; /* Reduce font size */
    color: white !important; 
  }

  .pre-order-btn {
    padding: 5px 10px; /* Smaller buttons */
    font-size: 10px; /* Smaller font size for buttons */
  }
}


.typing-text {
  overflow: hidden; /* Hide overflow text */
  white-space: nowrap; /* Prevent line breaks */
  border-right: 1px solid white; /* Add a typing cursor effect */
  margin: 0;
  padding: 0;
  display: inline;
  animation: typing 3s steps(25, end), blink-caret 0.5s step-end infinite;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from, to {
    border-color: transparent;
  }
  50% {
    border-color: white;
  }
}

.start-building-btn {
  background-color: #dbfa60; /* Example color, adjust as needed */
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s;
}
.start-building-btn a {
  text-decoration: none;
  color: black; /* Text color */
  font-weight: bold;
}

.start-building-btn:hover {
  background-color: #2a2c2d; /* Darker shade for hover effect */
  color: white;
}


.ai-symbol {
  font-family: Arial, sans-serif; /* Arial or any preferred font */
  font-size: 0.1em; /* Smaller size relative to the surrounding text */
  vertical-align: super; /* Superscript position */
  font-weight: bold; /* Optional: for bolder text */
}

.github-icon {
  color: white;      /* Setting the color to white */
  font-size: 24px;   /* Adjust the size as needed */
  align-content: left;
}


@media (max-width: 768px) {
  .custom-table th, .custom-table td {
      padding: 8px; /* Reduce padding */
      font-size: 12px; /* Reduce font size if necessary */
  }
}

.responsive-image {
  max-width: 50%;

 
}

/* Example for smaller screens (e.g., smartphones) */
@media screen and (max-width: 600px) {
  .responsive-image {
      max-width: 50%;
      
  }
}

/* Example for medium screens (e.g., tablets) */
@media screen and (min-width: 601px) and (max-width: 1024px) {
  .responsive-image {
      max-width: 80%;
  }
}

.email-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: none;
  border-radius: 5px;
}

.subscribe-btn {
  background-color: #dbfa60;
  color: #000;
  padding: 10px 20px;
  border: none;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.subscribe-btn:hover {
  background-color: #dbfa60;
}

.newsletter-form {
  background-color: #161515;
  opacity: 100%;
  border: none;
  padding: 40px;
  border-radius: 5px;
  max-width: 600px;
  margin: 0 auto;
}

.newsletter-heading {
  font-size: 28px; /* Increase the font size to make the text bigger */
  margin-bottom: 20px;
}